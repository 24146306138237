const hrUrl = process.env.VUE_APP_HR_URL;
const olkUrl = process.env.VUE_APP_OLK_URL;

export const externalUrls = {
	kpiExport: "http://reporting/reports/report/%D0%9E%D1%82%D1%87%D0%B5%D1%82%D1%8B/%D0%A3%D0%A0%D0%9F/%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20KPI%20%D1%81%D0%BE%D1%82%D1%80%D1%83%D0%B4%D0%BD%D0%B8%D0%BA%D0%BE%D0%B2%20%D0%A4%D0%BE%D0%BD%D0%B4%D0%B0%20%D0%B7%D0%B0%20%D0%B3%D0%BE%D0%B4",
	informationSystems: `${olkUrl}/apps`,
	hrProfile: `${hrUrl}/me`,
	manuals: {
		kpiKpis: "https://teamly.frprf.ru/space/cdf62100-a6a4-4464-9e98-54ad20294e9e/article/a78af83d-7b17-4458-9c1e-31d23c9817dd"
	}
};
