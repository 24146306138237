import { IsArray, IsEnum, IsString } from "class-validator";
import { KpiStatusEnum } from "@/types/kpi/kpiStatusEnum";
import { KpiQuarterEnum } from "@/types/kpi/KpiQuarterEnum";

export default class KpisRouteQuery {
	quarter: KpiQuarterEnum | null;
	
	@IsArray()
	staffIds: string[];
	
	@IsString()
	departmentId: string;
	
	@IsString()
	kpi: string;
	
	@IsString()
	@IsEnum(KpiStatusEnum)
	status: KpiStatusEnum;
	
	constructor(
		quarter: KpiQuarterEnum | null = null,
		staffIds: string[] = [],
		departmentId: string = "",
		kpi: string = "",
		status: KpiStatusEnum = KpiStatusEnum.ALL
	)
	{
		this.quarter = quarter;
		this.staffIds = staffIds;
		this.departmentId = departmentId;
		this.kpi = kpi;
		this.status = status;
	}
}
